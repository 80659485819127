<div class="centered-container">
  <img class="logo" src="./images/logo_new.png" alt="log" />
  <div class="title">Game is starting soon!</div>
  <img src="images/starting.svg" width="150px" alt="loading" />
</div>

<style>
  .title {
    font-family: "Alfa Slab One", cursive;
    font-size: 2.5em;
    margin-bottom: 1em;
  }
</style>
